import React from 'react';
import { useNavigate } from "react-router-dom";
import "./ProjectComponent.css";

const ProjectComponent = (props) => {
    const navigate = useNavigate();

    return (
        <div className="project-component" onClick={() => navigate(`/projects/${props.id}`)}>
            <h3>{props.title}</h3>
            <p>{props.description.lenght < 100 ? props.description : props.description.substring(0,props.description.substring(0, 100).lastIndexOf(' ')) + '...'}</p>
        </div>
    );
}

export default ProjectComponent;