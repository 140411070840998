import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './LoginRegister.css';

const LoginRegister = () => {
    const navigate = useNavigate();
    const data = {
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
    };
    const [error, setError] = useState({
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
    });

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    const ValidateEmail = (mail) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const err = {};
        if (!data.email) {
            err.email = "You must provide an email address";
        }

        if (!data.password) {
            err.password = "You must provide a password";
        }

        if (!data.password_confirmation) {
            err.password_confirmation = "You must provide a password confirmation";
        }

        if (!data.username) {
            err.username = "You must provide a username";
        }

        if (data.password !== data.password_confirmation) {
            err.password = "Your passwords don't match";
        }

        if (!ValidateEmail(data.email)) {
            err.email = "You must provide a valid email address";
        }

        if (!err.email && !err.username && !err.password && !err.password_confirmation) {
            // send data to backend api
            await fetch("http://localhost:4000/user/register", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: data.email,
                    username: data.username,
                    password: data.password,
                })
            });
            console.log("registration Success");
            navigate("/profile")
        }
        setError(err);
    }

    return (
        <div className="account-management-wrapper">
            <div className="initial-message-container">
                <h2>Sign Up</h2>
                <h3 onClick={() => navigate("/login")}>Already have an account? Click here to Log In</h3>
            </div>

            <div className="separator"></div>

            <form onSubmit={handleSubmit}>
                <div className="fieldWrapper">
                    <label htmlFor="username">Username:</label>
                    <input type="text" name="username" id="username" autoComplete="no" onChange={(e) => data.username = e.target.value} />
                    <span className="error">{error.username}</span>
                </div>
                <div className="fieldWrapper">
                    <label htmlFor="email">E-Mail:</label>
                    <input type="email" name="email" id="email" onChange={(e) => data.email = e.target.value} />
                    <span className="error">{error.email}</span>
                </div>
                <div className="fieldWrapper">
                    <label htmlFor="password">Password:</label>
                    <input type="password" name="password" id="password" onChange={(e) => data.password = e.target.value} />
                    <span className="error">{error.password}</span>
                </div>
                <div className="fieldWrapper">
                    <label htmlFor="confirm-password">Confirm Password:</label>
                    <input type="password" name="confirm-password" id="confirm-password" onChange={(e) => data.password_confirmation = e.target.value} />
                    <span className="error">{error.password_confirmation}</span>
                </div>
                <input type="submit" value="Sign Up" />
            </form>
            
            <div className="separator"></div>

            <div className="sign-in-with-google">
                <div id="g_id_onload"
                    data-client_id="35791712962-3gcopp53h22iuopuhkt8a3eqq2tg8p83.apps.googleusercontent.com"
                    data-login_uri="http://localhost:4000/auth/google" 
                    data-auto_prompt="false">
                </div>
                <div className="g_id_signin"
                    data-type="standard"
                    data-size="large"
                    data-theme="outline"
                    data-text="sign_in_with"
                    data-shape="rectangular"
                    data-logo_alignment="left">
                </div>
            </div>
        </div>
    );
}

export default LoginRegister;