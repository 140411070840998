import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [clicked, setClicked] = useState(false);

  function flip() {
    setClicked(!clicked);
  }

  return (
    <nav>
        <ul className="standard-ul">
          <div className="links">
            <div className='linkGroup'>
              <li>
                  <Link to={`/`} className='link'>Home</Link>
              </li>
              <li>
                  <Link to={`/create`} className="link">Create</Link>
              </li>
            </div>
   
            <div className='linkGroup'>
              <li>
                  <Link to={`/explore`} className='link'>Explore</Link>
              </li>
              <li>
                  <Link to={`/profile`} className="link">My Profile</Link>
              </li>
            </div>
          </div>
        </ul>
        <div className="hamburger" onClick={flip}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className='hambar-ul-wrapper'>
          <ul className={clicked ? "hambar-ul" : "hidden"}>
              <li>
                  <Link to={`/home`} className="link">Home</Link>
              </li>
              <li>
                  <Link to={`/create`} className="link">Create</Link>
              </li>
              <li>
                  <Link to={`/explore`} className="link">Explore</Link>
              </li>
              <li>
                  <Link to={`/my-profile`} className="link">My Profile</Link>
              </li>
          </ul>
        </div>
    </nav>
  );
}

export default Navbar;