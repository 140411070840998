import React from 'react'
import './Drumname.css'
const Drumname = ({name}) => {
  return (
    <div className='drumname-wrapper'>
        <div className="p">{name}</div>
        <input type="checkbox" name="checkbox1" id=""  className='ch1'/>
    </div>
  )
}

export default Drumname