import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import "./Layout.css";

const Layout = ({children}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
        <Navbar />
        <main>
            {children}
        </main>
        <Footer />
    </>
  );
}

export default Layout;