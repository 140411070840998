import React from 'react';
import { GrInstagram } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { MdLogin } from 'react-icons/md';
import { BiNotepad } from 'react-icons/bi';
import { VscAccount } from 'react-icons/vsc';
import { BsFacebook, BsPinterest, BsTwitter } from 'react-icons/bs';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className='cols'>
        <div className="col">
          <h3>Social media</h3>
          <ul>
            <li>
              <a href="https://instagram.com" target="_blank">
                <GrInstagram className='icon' />
                Instagram
              </a>
            </li>
            <li>
              <a href="https://facebook.com" target="_blank">
                <BsFacebook className='icon'/>
                Facebook
              </a>
            </li>
            <li>
              <a href="https://twitter.com" target="_blank">
                <BsTwitter className='icon'/>
                Twitter
              </a>
            </li>
            <li>
              <a href="https://pinterest.com" target="_blank">
                <BsPinterest className='icon'/>
                Pinterest
              </a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>Account Management</h3>
          <ul>
            <li>
              <Link to="profile">
                <VscAccount className='icon' />
                View profile
              </Link>
            </li>
            <li>
              <Link to="register">
                <BiNotepad className='icon' />
                Create a new account
              </Link>
            </li>
            <li>
              <Link to="login">
                <MdLogin className='icon' />
                Log into your account
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; Copyright 2022 myBeat
      </div>
    </footer>
  );
}

export default Footer;