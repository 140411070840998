import React from 'react';
import { useNavigate } from "react-router-dom";
import "./AboutApp.css";

const AboutApp = () => {
    const navigator = useNavigate();

    return (
        <div className="about-wrapper">
            <div className="cover">
                <h3>The app that helps you to truly express yourself</h3>
                <button onClick={() => navigator("/explore")}>See what others have created</button>
            </div>
        </div>
    );
}

export default AboutApp;