import React from 'react'
import './Drumbutton.css'

let state=false;
export function onClickButton(){
  state = !state;
  console.log(state);
}
console.log(state);
const Drumbutton = (props) => {
  return (
    <>
        <div className="square" id={props.id}>
           
        </div>
    </>
  )
}

export default Drumbutton