import React from 'react';
import "./Settings.css";

const Settings = ({ uid }) => {
    async function handleAvatar(e) {
        e.preventDefault();

        const input = document.querySelector('input[type="file"]');

        const data = new FormData();

        console.log(input.files)

        if (input.files[0]) {
            data.append('avatar', input.files[0]);
    
            await fetch(`/user/avatar/${uid}`, {
                method: "PUT",
                body: data
            });
            
            window.location.reload(false);
        }
    }

    async function handleSettings(e) {
        e.preventDefault();
        const data = {};

        if (e.target.username.value) {
            data.username = e.target.username.value;
        }

        if (e.target.email.value) {
            data.email = e.target.email.value;
        }

        if (e.target.password.value && e.target.password_confirmation.value) {
            if (e.target.password.value === e.target.password_confirmation.value) {
                data.password = e.target.password.value;
            }
        }

        await fetch(`/user/${uid}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });

        window.location.reload(false);
    }
    return (
        <div className="settings-wrapper">
            <form onSubmit={handleAvatar} className='avatarForm'>
                <input type="file" name="avatar" id="avatar" />
                <input type="submit" value="Change avatar" />
            </form>
            <form onSubmit={handleSettings} className='settingsForm'>
                <label>
                    New username:
                    <input type="text" name="username" id="username" />
                </label>
                <label>
                    New email:
                    <input type="text" name="email" id="email" />
                </label>
                <label>
                    New password:
                    <input type="password" name="password" id="password" />
                </label>
                <label>
                    Confirm new password:
                    <input type="password" name="password_confirmation" id="password_confirmation" />
                </label>

                <input type="submit" value="Update settings" />
            </form>
        </div>
    );
}

export default Settings;