import React from 'react';
import { useNavigate } from "react-router-dom";
import './GetStarted.css';

const GetStarted = () => {
    const navigator = useNavigate();
    return (
        <div className="gs-wrapper">
            <h3>Set your inner artist free</h3>
            <button onClick={() => navigator("/create")}>Begin your journey</button>
        </div>
    );
}

export default GetStarted;