import React from 'react'
import Drumbutton from '../Drumbutton/Drumbutton'
import Drumname from '../Drumname/Drumname'
import './Drumbox.css'
import {Howl} from 'howler';
import {onClickButton} from '../Drumbutton/Drumbutton'




const Drumbox = () => {
  let j=0;
  let drumname=["Kick","Snare","Closed Hat","Open Hat","Rim"]
  let beats = [new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array(), new Array()];
const sounds = [
  new Howl({
      src: ['../sounds/hh-chat.wav']
  }),
  new Howl({
      src: ['../sounds/hh-kick.wav']
  }),
  new Howl({
      src: ['../sounds/hh-ohat.wav']
  }),
  new Howl({
      src: ['../sounds/hh-snare.wav']
  }),
  new Howl({
      src: ['../sounds/rnb-chat.wav']
  }),
  new Howl({
      src: ['../sounds/rnb-kick.wav']
  }),
  new Howl({
      src: ['../sounds/rnb-ohat.wav']
  }),
  new Howl({
      src: ['../sounds/rnb-snare.wav']
  }),
  new Howl({
      src: ['../sounds/trap-chat.wav']
  }),
  new Howl({
      src: ['../sounds/trap-kick.wav']
  }),
  new Howl({
      src: ['../sounds/trap-ohat.wav']
  }),
  new Howl({
      src: ['../sounds/trap-snare.wav']
  }),
  new Howl({
      src: ['../sounds/trap-rim.wav']
  })
]
function onClickPlay(bpm){
  sounds[4].play();
  let time=60000/bpm
  let interval=setInterval(()=>{
    beats.forEach(beat => {
      beat.forEach(b=>{
        sounds[b].play();
      })
    });
  },time)
  
}
  return (
    <div className='drumbox-wrapper'>
            
             <div className="drumbox-row">
             <Drumname name={drumname[0]}/>
             {(() => {
              let drumbuttons = [];
              for (let i = 1; i <= 16; i++) {
              drumbuttons.push(<Drumbutton id={j} onClick={beats[j%16].push(1)}/>);
              j++;
              }
              return drumbuttons;
              })()} 
             </div>
             <div className="drumbox-row">
            <Drumname  name={drumname[1]}/>
            {(() => {
              j = 0;
              let drumbuttons = [];
              for (let i = 1; i <= 16; i++) {
              drumbuttons.push(<Drumbutton id={j} onClick={beats[j%16].push(2)}/>);
              j++;
              }
              return drumbuttons;
              })()} 
            </div>
            <div className="drumbox-row">
            <Drumname name={drumname[2]}/>
            {(() => {
              j = 0;
              let drumbuttons = [];
              for (let i = 1; i <= 16; i++) {
              drumbuttons.push(<Drumbutton id={j} onClick={beats[j%16].push(3)}/>);
              j++;
              }
              return drumbuttons;
              })()} 
            </div>
            <div className="drumbox-row">
            <Drumname name={drumname[3]}/>
            {(() => {
              j = 0;
              let drumbuttons = [];
              for (let i = 1; i <= 16; i++) {
              drumbuttons.push(<Drumbutton id={j} onClick={beats[j%16].push(4)}/>);
              j++;
              }
              return drumbuttons;
              })()} 
            </div>
            <div className="drumbox-row">
            <Drumname name={drumname[4]}/>
            {(() => {
              let drumbuttons = [];
              for (let i = 1; i <= 16; i++) {
              drumbuttons.push(<Drumbutton id={j} onClick={beats[j%16].push(3)}/>);
              j++;
              }
              return drumbuttons;
              })()} 
            </div>
       
            <button onClick={onClickPlay(90)}>Play</button>
        
            
            
            
    </div>
  )
}

export default Drumbox