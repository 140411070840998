import React from 'react'
import Drumbox from '../../components/Layout/Drumbox/Drumbox'
import './Create.css'
import {Howl} from 'howler';
import { beats1 } from '../../components/Layout/Drumbox/Drumbox';

const sounds = [
    new Howl({
        src: ['../sounds/hh-chat.wav']
    }),
    new Howl({
        src: ['../sounds/hh-kick.wav']
    }),
    new Howl({
        src: ['../sounds/hh-ohat.wav']
    }),
    new Howl({
        src: ['../sounds/hh-snare.wav']
    }),
    new Howl({
        src: ['../sounds/rnb-chat.wav']
    }),
    new Howl({
        src: ['../sounds/rnb-kick.wav']
    }),
    new Howl({
        src: ['../sounds/rnb-ohat.wav']
    }),
    new Howl({
        src: ['../sounds/rnb-snare.wav']
    }),
    new Howl({
        src: ['../sounds/trap-chat.wav']
    }),
    new Howl({
        src: ['../sounds/trap-kick.wav']
    }),
    new Howl({
        src: ['../sounds/trap-ohat.wav']
    }),
    new Howl({
        src: ['../sounds/trap-snare.wav']
    }),
    new Howl({
        src: ['../sounds/trap-rim.wav']
    })
]


let interval;
let bars1=[];
function playDrums(bpm, bars) {
  bars1=bars;
    let time = 60000 / bpm;
    let i = 0;
    interval = setInterval(()=> {
        i++;
        i = i % 16;
        for (let sound in bars1[i]) {
            sounds[sound].play();
        }
    }, time);
}

function stopDrums() {
    clearInterval(interval);
}
function changeInterface(){
  return(
    <p>promena</p>
  )
}

let options = [{'bpm' : 90, 'drums' : [0, 1, 2, 3]}, {'bpm' : 90, 'drums' : [4, 5, 6, 7]}, {'bpm' : 130, 'drums' : [8, 9, 10, 11, 12]}];

const Create = () => {
  let bpm=90;
  let bars=[[0],[0],[0],[0],[1],[0],[0],[1],[0],[0],[0],[0],[0],[0],[0],[0]]
  return (
    <>
      <select name="" id="" onChange={changeInterface}>
        <option value="0">Hip-Hop</option>
        <option value="1">R'n'B</option>
        <option value="2">Trap</option>
      </select>
      <Drumbox/>
      
    </>
  )
}

export default Create