import React, { useState } from 'react';
import ProjectComponent from '../../components/ProjectComponent/ProjectComponent';
import { useNavigate } from 'react-router-dom';
import "./Profile.css";
import Settings from '../../components/Settings/Settings';

const Profile = () => {
  const navigate = useNavigate();
  if (document.cookie.indexOf('logged_in=true') < 0) {
    navigate("/login");
  }

  const [username, setUsername] = useState('');
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const [settings, setSettings] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');

  fetch("/user/info", {
    method: "GET",
    credentials: "include"
  })
  .then(raw => raw.json())
  .then(d => {
    setUsername(d.username);
    setUid(d._id);
    setEmail(d.email);
    setAvatarUrl(`/user/avatar/${d._id}`);
  });

  async function logout() {
    await fetch("/user/logout", {
      method: "POST",
      credentials: "include"
    });
    navigate("/login");
  }

  return (
    <div className="profile-wrapper">
      <div className="sidebar">
        <img key={avatarUrl} src={avatarUrl} alt="user avatar" />
        <p>{username}</p>
        <p className='settings-button' onClick={() => setSettings(!settings)}>Change settings</p>
        {settings && <Settings uid={uid} />}
        <p className="settings-button logout" onClick={logout}>Log out</p>
      </div>
      <div className="profile-content">
        <h2>My Projects</h2>
        <div className="projects">
          <ProjectComponent id="djanidbaywbdyauyb" title="test" description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati, numquam omnis. Nihil recusandae provident incidunt non saepe esse pariatur natus exercitationem vero eum. Soluta, dolorum tempora ipsam sapiente eos exercitationem?" />
        </div>
      </div>
    </div>
  );
}

export default Profile;