import React from 'react';
import AboutApp from '../../components/AboutApp/AboutApp';
import Cover from '../../components/Cover/Cover';
import GetStarted from '../../components/GetStarted/GetStarted';
import './Home.css';

const Home = () => {
  return (
    <>
      <Cover />
      <AboutApp />
      <GetStarted />
    </>
  );
}

export default Home;