import {Routes,Route} from "react-router-dom";
import Home from './pages/Home/Home';
import Create from './pages/Create/Create';
import Explore from './pages/Explore/Explore';
import Profile from './pages/Profile/Profile';
import Layout from './components/Layout/Layout';
import Login from './pages/LoginRegister/Login';
import Register from './pages/LoginRegister/Register';

function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/create' element={<Create />}/>
          <Route path='/explore' element={<Explore />}/>
          <Route path='/profile' element={<Profile />}/>
          <Route path='/login' element={<Login />}/>
          <Route path='/register' element={<Register />}/>
        </Routes>
      </Layout>
    </>
  );
}

export default App;
