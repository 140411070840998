import React from 'react';
import "./Cover.css";

const Cover = () => {
  return (
    <div className="home">
        <div className="cover">
            <div className="ts-wrapper">
            <h1 className="title">myBeat</h1>
            <h2 className="subtitle">Make yourself heard</h2>
            </div>
        </div>
    </div>
  );
}

export default Cover;